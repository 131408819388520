import React from 'react';

export default function Certification() {
  return (
    <>
      <h1>
        This is Certification.
      </h1>
    </>
  );
}
