import React from "react";

export default function Home() {
  return (
    <>
      <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
        <div>
          <h2 className="text-3xl font-semibold text-gray-800 md:text-4xl dark:text-white">
            Welcome to{" "}
            <span className="text-purple-700 dark:text-purple-600">
              Salesforce Counsellor
            </span>
          </h2>
          <h4 className="text-xl font-semibold text-gray-800 md:text-2xl dark:text-white">
            Learn
            <span className="text-purple-700 dark:text-purple-600">
              Salesforce
            </span>
            <span className="typed-cursor" aria-hidden="true"></span>
          </h4>
          <p className="mt-2 text-sm text-gray-500 md:text-base dark:text-gray-400">
            "Your Vision, Our Expertise"
            <br />
            We are Empowering Success with Salesforce Solutions <br />
            👩‍🏫 Learn Salesforce <br />
            🚀 Unleash Business Potential with us
          </p>
          <div className="flex justify-center lg:justify-start mt-6">
            <button className="px-3 py-2 lg:px-4 lg:py-3 bg-gray-900 text-gray-200 text-xs font-semibold rounded hover:text-white hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-600 dark:hover:bg-gray-300">
              Free Courses
            </button>
            <button className="px-3 py-2 mx-4 lg:px-4 lg:py-3 bg-gray-300 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400"  >
            <a href="/Blogs"color="white" > Explore Blogs</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}