import React from "react";
import { MDBFooter, MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import insta from "./Image/Insta.png";
import linkedin from "./Image/linkedin.png";
import twitter from "./Image/twitter.png";
import youtube from "./Image/youtube.png";
import facebook from "./Image/facebook.png";
export default function Footer() {
   return (
    <>

    <MDBFooter className="text-center" color="white" bgColor="light">
      <MDBContainer className="p-4">
        <section className="mb-4">
          <div class="myDiv">
            {/* <h2 color="Black">Contact us</h2> */}
            <h1 style={{ color: 'Black' }}>Contact Us</h1>
          </div>

          <MDBBtn
            outline
            color="white"
            floating
            className="m-1"
            href="https://in.linkedin.com/company/salesforce-counsellor?trk=public_post_comment_actor-image"
            role="button"
          >
            <img src={linkedin} width={25} height={25} alt="linkedin logo" />
          </MDBBtn>

          <MDBBtn
            outline
            color="black"
            floating
            className="m-1"
            href="https://www.instagram.com/salesforcecounsellor/"
            role="button"
          >
            <img src={insta} width={25} height={25} alt="Insta logo" />
          
          </MDBBtn>

          <MDBBtn
            outline
            color="black"
            floating
            className="m-1"
            href="#!"
            role="button"
          >
            <img src={twitter} width={25} height={25} alt="twitter logo" />
          </MDBBtn>

          <MDBBtn
            outline
            color="black"
            floating
            className="m-1"
            href="https://www.youtube.com/@SalesforceCounsellor"
            role="button"
          >
            <img src={youtube} width={25} height={25} alt="youtube logo" />
          </MDBBtn>

          <MDBBtn
            outline
            color="black"
            floating
            className="m-1"
            href="#!"
            role="button"
          >
            <img src={facebook} width={25} height={25} alt="facebook logo" />
          </MDBBtn>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        ©️ 2023 Copyright:
        <a className="text-white" href="https://salesforcecounsellor.com/">
          Salesforcecounsellor.com
        </a>
      </div>
    </MDBFooter>
    </>
  );
}
