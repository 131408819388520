import image1 from "./Image/logo.png";
import image2 from "./Image/img1.png";
import image3 from "./Image/img2.png";
import React from 'react'

export default function Blog() {
  return (
  <>
  <div id="carouselExampleFade" className="carousel slide carousel-fade">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={image1}  width={1520} height={500} alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={image2} width={1520} height={500} alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={image3} width={1520} height={500} alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  </>

  )
}