import "./App.css";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
import Blog from "./Component/Blogs";
import AboutUs from "./Component/AboutUs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SalesforceDeveloper from "./Component/SalesforceDeveloper";
import SalesforceAdmin from "./Component/SalesforceAdmin";
import Certification from "./Component/Certification";
function App() {
  return (
    <>
      <Router>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/Blogs' element={<Blog/>} />
          <Route path='/AboutUs' element={<AboutUs/>} />
          <Route path='/Courses/SalesforceAdmin' element={<SalesforceAdmin/>} />
          <Route path='/Courses/SalesforceDeveloper' element={<SalesforceDeveloper/>} />
          <Route path='/Certification' element={<Certification/>} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
